// Generated by Framer (41c59c7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["ouiDx3TeD"];

const serializationHash = "framer-aM5Ag"

const variantClassNames = {ouiDx3TeD: "framer-v-ztz7pc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, scrollSection, width, ...props}) => { return {...props, LCD9OL0pd: scrollSection ?? props.LCD9OL0pd} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;scrollSection?: React.MutableRefObject<HTMLElement>; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, LCD9OL0pd, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ouiDx3TeD", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><MotionDivWithFX {...restProps} __framer__styleTransformEffectEnabled __framer__transformTargets={[{target: {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}}, {ref: LCD9OL0pd, target: {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}}]} __framer__transformTrigger={"onScrollTarget"} __framer__transformViewportThreshold={0.5} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ztz7pc", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ouiDx3TeD"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-1aa7a1f9-664a-40d1-a64a-074a2f9865c7, rgb(255, 255, 255))", borderBottomLeftRadius: 24, borderBottomRightRadius: 24, ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-aM5Ag.framer-t4a8vc, .framer-aM5Ag .framer-t4a8vc { display: block; }", ".framer-aM5Ag.framer-ztz7pc { height: 3712px; overflow: hidden; position: relative; width: 1424px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 3712
 * @framerIntrinsicWidth 1424
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"LCD9OL0pd":"scrollSection"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerwZbsxl9bU: React.ComponentType<Props> = withCSS(Component, css, "framer-aM5Ag") as typeof Component;
export default FramerwZbsxl9bU;

FramerwZbsxl9bU.displayName = "filling-back";

FramerwZbsxl9bU.defaultProps = {height: 3712, width: 1424};

addPropertyControls(FramerwZbsxl9bU, {LCD9OL0pd: {title: "Scroll Section", type: ControlType.ScrollSectionRef}} as any)

addFonts(FramerwZbsxl9bU, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})